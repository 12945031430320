import React from 'react';
import styled from 'styled-components';

import OverlayButton from './OverlayButton';

const OverrideButton = styled(OverlayButton)`
  justify-self: center;
  align-self: start;
  margin-top: 1em;
`;

const CloseButton = (props) => {
  return <OverrideButton eventHandler={props.eventHandler} image={props.image} />
}

export default CloseButton;