import React, { Component } from 'react';
import queryString from 'query-string';

import Navigation from './Navigation';
import GalleryContainer from './Gallery/GalleryContainer';

class App extends Component {
  constructor() {
    super();
    const { id_token, access_token, token_type } = {...queryString.parse(window.location.hash)};
    this.state = {
      accessToken: access_token,
      idToken: id_token,
      tokenType: token_type
    };
  }

  async componentDidMount() {
    if (!this.validateLogin()) {
      return;
    }

  }

  validateLogin = async () => {
    if (!this.state.tokenType || !this.state.accessToken || !this.state.idToken) {
      return false;
    }

    const response = await fetch('https://auth.clubdecora.net/oauth2/userInfo',
      {headers: {'Authorization': `${ this.state.tokenType } ${ this.state.accessToken }`}});
    const json = await response.json();

    if (response.status !== 200) {
      return false;
    }

    this.setState({username: json.username});
    this.setState({isLoggedIn: true});
    return true;
  }

  render() {
    return (
      <React.Fragment>
        <Navigation isLoggedIn={this.state.isLoggedIn} />
        {
          this.state.isLoggedIn && this.state.idToken
          ? <GalleryContainer token={this.state.idToken} tokenType={this.state.tokenType} />
          : ''
        }
      </React.Fragment>
    );
  }
}

export default App;
