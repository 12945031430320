import React, { Component } from 'react';
import styled from 'styled-components';

import FullScreenGallery from './FullScreenGallery';
import Gallery from './Gallery';
import Upload from './Upload';
import Button from '../DefaultStyles/Button';

const Main = styled.main`
display: contents;
`;

const Loader = styled.img`
  margin: auto;
`;

class GalleryContainer extends Component {
  constructor() {
    super();
    this.state = {
      isFullscreen: false,
      files: {
        children:{}
      },
      errors: '',
      isShowUpload: false,
      structure: {},
      folderLevel: [],
      loading: true
    };
  }

  async componentDidMount() {
    await this.getFiles();
    document.addEventListener("keydown", this.handleKeyDown, false); 
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  setStructure = (files) => {
    let folders = {
      type: 'folder',
      children: {}
    };
    for(const file of files) {
      const splitFile = file.FileName.split('/');
      folders.children = this.getFolderStructure(splitFile, file.Link, folders.children);
    }
    return folders;
  }

  getFolderStructure = (file, url, object) => {
    if (file.length === 1) {
      const entries = Object.entries(object);
      const index = entries.filter(entry => entry[1].type !== 'folder').length;
      object[file[0]] = {
         url: url,
         type: 'image',
         name: file[0],
         index: index
      };
      return object;
    }
    const folder = file.shift();
    if (!object[folder]) {
      object[folder] = {
        type: 'folder',
        name: folder,
        children: {}
      };
    }
    object[folder].children = this.getFolderStructure(file, url, object[folder].children);
    return object;
  }

  getFolderGallery = (folder) => {
    let newFolder = {};
    for (const item in this.state.files.children) {
      if (item === folder) {
        newFolder = this.state.files.children[item];
        break;
      }
    }
    const folderLevel = this.state.folderLevel;
    folderLevel.push(folder);
    this.setState({folderLevel: folderLevel});
    const selectedFile = Object.entries(newFolder.children).find(child => child[1].index === 0);
    this.setState({selectedFile: selectedFile[1]});
    this.setState({files: newFolder});
  }

  goBack = () => {
    let newFolder = this.state.structure;
    const folderLevel = this.state.folderLevel;
    folderLevel.pop();
    for (let i = 0; i < folderLevel.length; i++) {
      newFolder = newFolder.children[folderLevel[i]];
    }
    
    this.setState({folderLevel: folderLevel});
    this.setState({files: newFolder});
    const selectedFile = Object.entries(newFolder.children).find(child => child[1].index === 0);
    this.setState({selectedFile: selectedFile[1]});
  }

  goToRoot = () => {
    this.setState({folderLevel: []});
    this.setState({files: this.state.structure});
    const selectedFile = Object.entries(this.state.structure.children).find(child => child[1].index === 0);
    this.setState({selectedFile: selectedFile[1]});
  }

  getFiles = async () => {
    const responseStream = await fetch(
      'https://api.clubdecora.net/v1/getpresignedurls', 
      {
        headers: {
          'Authorization': `${ this.props.tokenType } ${ this.props.token }`
        }
      });
    if (responseStream.status !== 200) {
      console.log (responseStream.status);
      this.setState({loading: true});
      return;
    }
    const response = await responseStream.json();
    const sortedResponse = response.sort((a, b) => {
      if(a.FileName && a.FileName.split('/').length > 1) {
        return -1;
        } else {
        return 1;
        }
    });
    const folders = this.setStructure(sortedResponse);
    this.setState({ structure: folders });
    this.setState({ files: folders });
    const selectedFile = Object.entries(this.state.files.children).find(child => child[1].index === 0);
    this.setState({selectedFile: selectedFile[1]});
    this.setState({loading: false});
  }

  onDrop = async files => {
    this.setState({errors: ''});
    const filePaths = files.map(file => `${ this.state.folderLevel.join('/') }${ file.path[0] === '/' ? file.path : `/${ file.path }` }`);
    const responseStream = await fetch(
      'https://api.clubdecora.net/v1/getpresignedurls', 
      {
        method: 'POST', 
        body: JSON.stringify(filePaths),
        headers: {
          'Authorization': `${ this.props.tokenType } ${ this.props.token }`
        }
      });
    const response = await responseStream.json();
    await this.sendFiles(response, files);
    this.setState({errors: `Files uploaded.`});
  }

  sendFiles = async (response, files) => {
    let i = 0;
    for  (const url of response) {
      if (url.Error) {
        this.setState({errors: `${ this.state.errors } ${ url.FileName } - ${ url.Error.Message }.`});
        return;
      }
      await fetch(
        url.Link,
        {
          method: 'PUT',
          body: files[i]
        });
      i++;
    }
  }

  handleFullScreen = (file) => {
    console.log(`going ${ !this.state.isFullscreen ? 'big' : 'small' }`);
    this.setState(prevState => ({isFullscreen: !prevState.isFullscreen}));
    if (file) {
      this.setState({selectedFile: file});
    }
  }

  changeImage = (increment) => {
    console.log('changing image')
    let newIndex = this.state.selectedFile.index + increment;
    const numberOfFiles = Object.entries(this.state.files.children).filter(child => typeof child[1].index === 'number').length;
    if (newIndex < 0) {
      newIndex = numberOfFiles - 1;
    } else if (newIndex >= numberOfFiles) {
      newIndex = 0;
    }
    const newFile = Object.entries(this.state.files.children).find(child => child[1].index === newIndex);
    this.setState({ selectedFile: newFile[1] });
  }

  previousImage = (e) => {
    e.preventDefault();
    this.changeImage(-1);
  }

  nextImage = (e) => {
    e.preventDefault();
    this.changeImage(1);
  }

  handleKeyDown = (e) => {
    console.log(`${ e.code } was pressed`)
    if (e.code === 'Space') {
      this.handleFullScreen();
    } else if (this.state.isFullscreen) {
      switch (e.code) {
        case 'ArrowLeft':
          this.changeImage(-1);  
          break;
        case 'ArrowRight':
          this.changeImage(1);  
          break;
        case 'Escape':
          this.handleFullScreen();
          break;
        default:
          break;
      }
    } else {
      if (e.code === 'KeyU') {
        this.toggleShowUpload();
      } else if (e.ctrlKey === true && e.code === 'Backspace' && !this.isTopLevel()) {
        this.goToRoot();
      } else if (e.code === 'Backspace' && !this.isTopLevel()) {
        this.goBack();
      }
    }
  }

  isTopLevel = () => {
    return this.state.folderLevel.length === 0;
  }

  toggleShowUpload = () => {
    this.setState(prevState => ({isShowUpload: !prevState.isShowUpload}));
  }

  render() {
    return <Main>
      {
        <React.Fragment>
          <Button as="button" onClick={this.toggleShowUpload} >
            {
              this.state.isShowUpload
                ? 'Close upload box'
                : 'Upload images or videos'
            }
          </Button>
          {
            this.state.isShowUpload
            ? <Upload token={this.props.token} tokenType={this.props.tokenType} onDrop={this.onDrop} />
            : ''
          }
        </React.Fragment>
      }      
      {this.state.loading
        ? <Loader src="../images/loading.gif" />
        : this.state.isFullscreen && this.state.selectedFile
          ? <FullScreenGallery url={this.state.selectedFile.url} handleFullScreen={this.handleFullScreen} previousImage={this.previousImage} nextImage={this.nextImage}/>
          : <Gallery 
              files={this.state.files.children} 
              handleFullScreen={this.handleFullScreen} 
              getFolderGallery={this.getFolderGallery} 
              goBack={this.goBack} 
              goToRoot={this.goToRoot} 
              isTopLevel={this.isTopLevel()} 
              previousImage={this.previousImage} 
              nextImage={this.nextImage}
              isFullscreen={this.state.isFullscreen}
            />
        
      }
    </Main>
  }
}

export default GalleryContainer;