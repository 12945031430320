import styled from 'styled-components';

const Button = styled.a `
  font-family: Calibri;
  font-weight: bold;
  color: #000;
  background-color: #D55;
  padding: 0.5em 1em;
  margin: 0.25em;
  text-decoration: none;
  border-radius: 0.5em;
  width: fit-content;
`;

export default Button;