import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  border: none;
  border-radius: 0;
  background-color: transparent;
  padding: 0 1em;
`;

const Image = styled.img`
  background-color: #FFF;
  border-radius: 3em;
  width: 4rem; 
`;

const OverlayButton = (props) => {
  return <Button className={props.className} onClick={props.eventHandler}>
      <Image src={props.image} />
    </Button>
}

export default OverlayButton;