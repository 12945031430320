import React from 'react';
import styled from 'styled-components';

import Button from '../DefaultStyles/Button';

const Grid = styled.div`
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-gap: 1rem;
`;

const Image = styled.img`
  width: 100%;
  max-height: 10rem;
  object-fit: cover;
`;

const ListItem = styled.li`
  display: flex;
  list-style: none;
  justify-content: center;
`;

const Text = styled.p`
  position: fixed;
  margin: auto;
  align-self: center;
  cursor: default;
`;

const Gallery = (props) => {
  return <div>
    {
      !props.isTopLevel
      ? <div>
          <Button as="button" onClick={props.goBack}>Back</Button>
          <Button as="button" onClick={props.goToRoot}>To root</Button>
        </div>
      : null
    }
    <Grid>
      {
        Object.entries(props.files).map((entry, i) => {
          const file = entry[1];
          if (file.type === 'folder') {
            return <ListItem key={i} onClick={() => props.getFolderGallery(file.name)} >
              <Image src="../images/folder.svg" />
              <Text>{file.name}</Text>
            </ListItem>
          }
          return <Image key={i} src={file.url} onClick={() => props.handleFullScreen(file)} />
        })
      }
    </Grid>
  </div>
};

export default Gallery;