import React from 'react';
import styled from 'styled-components';

import Button from './DefaultStyles/Button';

const Container = styled.header`
  padding: 0.1em;
  display: flex;
  background-color: #000;
`;


const NavButton = styled(Button)`
  background-color: #000;
  color: #CCC;
`;

const Navigation = (props) => {
  const domain = 'https://auth.clubdecora.net/';
  const parameters = '?response_type=token&client_id=2003rmvnk0udhm0bbbfslsgoit&';
  const url = props.isLoggedIn
    ? `${ domain }logout${ parameters }logout_uri=${ window.location.origin }`
    : `${ domain }login${ parameters }redirect_uri=${ window.location.origin }`;
  
  return <Container>
      <NavButton href={url}>
      { 
        props.isLoggedIn 
        ? 'Logout'
        : 'Login'
      }
      </NavButton>
      <NavButton>
        Gallery
      </NavButton>
    </Container>;
}

export default Navigation;