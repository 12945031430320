import React from 'react';
import styled from 'styled-components';

import OverlayButton from './OverlayButton';
import CloseButton from './CloseButton';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 5em auto 5em;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0%;
`;

const ImageContainer = styled.div`
  background-color: #000;
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

const Image = styled.img`
  margin: auto;
  max-width: 100%;
  max-height: 100%;
`;

const FullScreenGallery = (props) => {
  return <React.Fragment>
      <ImageContainer>
        <Image src={props.url} />
      </ImageContainer>
      <Grid>
        <OverlayButton eventHandler={props.previousImage} image="../images/left-arrow.svg" />
        <CloseButton eventHandler={props.handleFullScreen} image="../images/close_icon.svg" />
        <OverlayButton eventHandler={props.nextImage} image="../images/right-arrow.svg" />
      </Grid>
    </React.Fragment>
}
  

export default FullScreenGallery;