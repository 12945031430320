import React from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';

const Blur = styled.div`
  padding: 1em;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 20em;
  background-color: #EEE;
  border-radius: 1em;
  border: 1px black solid;
  box-sizing: border-box;
`;

const Text = styled.div`
  margin: auto;
`;

const Upload = (props) => {
  return <Blur>
      <Dropzone onDrop={props.onDrop} accept="image/jpeg, image/gif, image/png, video/mpeg, video/mp4, video/x-msvideo">
          {({getRootProps, getInputProps, isDragRejected}) => (
            <Container {...getRootProps()}>
              <Text >
                <input {...getInputProps()} />
                {!isDragRejected && "Drag 'n' drop some files here, or click to select files"}
                {isDragRejected && "File type not accepted, sorry!"}
              </Text>
            </Container>
          )}
        </Dropzone>
        <p>{ props.errors }</p>
    </Blur>;
    
};

export default Upload;